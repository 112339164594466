import React from 'react';
import SEO from '../components/SEO';

import {
  Information,
  PostItemStyled,
  SectionStyled,
} from '../components/Terms/Terms.styled';

const TermsConditions = () => (
  <>
    <SEO title="Terminos y condiciones" />
    <SectionStyled className="section">
      <div className="container">
        <PostItemStyled>
          <Information>
            <h1>Política de Privacidad de Mataseguros</h1>
            <p>
              En Mataseguros, nos comprometemos a proteger la privacidad y
              seguridad de los datos personales de nuestros usuarios. Esta
              Política de Privacidad explica cómo recopilamos, utilizamos,
              almacenamos y protegemos la información personal que
              proporcionas al utilizar nuestros servicios y acceder a nuestro
              sitio web.
            </p>

            <h2>Información sobre la Empresa y Datos de Contacto</h2>
            <p>
              Esta política de privacidad se aplica al sitio web de
              Mataseguros. Si tienes alguna pregunta o solicitud relacionada
              con tus datos personales, puedes contactarnos a través de los
              siguientes canales:
            </p>
            <ul>
              <li>
                Dirección postal: 514 N Armour St, 60614 Chicago, Illinois
              </li>
              <li>
                Correo electrónico:{' '}
                <a href="mailto:aitor@mataseguros.com">
                  aitor@mataseguros.com
                </a>
              </li>
              <li>Teléfono: +34 614 14 05 32</li>
            </ul>

            <h2>Recopilación de Información Personal</h2>
            <p>
              Nuestro sitio web puede recopilar información personal como:
            </p>
            <ul>
              <li>Nombre</li>
              <li>Dirección de correo electrónico</li>
              <li>Número de teléfono</li>
              <li>Tipo de siniestro</li>
              <li>Mensaje adicional</li>
            </ul>

            <h2>Uso de la Información Recopilada</h2>
            <p>La información recopilada se utiliza con el fin de:</p>
            <ul>
              <li>Proporcionar el mejor servicio posible.</li>
              <li>Mantener un registro de usuarios y solicitudes.</li>
              <li>Mejorar nuestros productos y servicios.</li>
              <li>
                Contactar al cliente para el seguimiento de los siniestros o
                para responder a mensajes adicionales.
              </li>
            </ul>
            <p>
              No realizamos prácticas de spam. Los correos electrónicos que
              enviamos son relevantes para los servicios que ofrecemos y
              pueden incluir ofertas especiales o información relacionada con
              los productos que consideramos beneficiosos para usted. En
              cualquier momento, puede cancelar la recepción de estos correos
              mediante el enlace de baja en los mismos.
            </p>

            <h2>Seguridad de la Información</h2>
            <p>
              Estamos comprometidos a mantener la seguridad de la información
              personal de nuestros usuarios. Utilizamos sistemas avanzados y
              actualizados regularmente para garantizar que no haya acceso no
              autorizado a los datos.
            </p>

            <h2>Conservación de Datos</h2>
            <p>
              Conservaremos tus datos personales durante el tiempo necesario
              para cumplir con los fines para los que fueron recopilados, así
              como para cumplir con nuestras obligaciones legales. En caso de
              no necesitar tus datos en el futuro, los eliminaremos de manera
              segura, aunque en algunos casos los datos podrán ser conservados
              indefinidamente si así lo requiere la normativa vigente o con
              fines legítimos.
            </p>

            <h2>Cookies</h2>
            <p>
              El sitio web utiliza cookies para mejorar la experiencia del
              usuario. Las cookies son pequeños archivos que se almacenan en
              tu ordenador para procesar los datos y analizar el tráfico web.
              La mayoría de los navegadores aceptan cookies de forma
              automática, pero puedes modificar la configuración de tu
              navegador para rechazarlas. Ten en cuenta que rechazar las
              cookies podría afectar el funcionamiento de algunas de nuestras
              funciones.
            </p>

            <h2>Enlaces a Sitios de Terceros</h2>
            <p>
              Nuestro sitio web puede contener enlaces a sitios de terceros
              que podrían ser de tu interés. Una vez que hagas clic en esos
              enlaces y abandones nuestra página, ya no tenemos control sobre
              el sitio al que serás redirigido. No somos responsables de la
              privacidad ni de la protección de tus datos en esos sitios. Te
              recomendamos consultar las políticas de privacidad de esos
              sitios.
            </p>

            <h2>Servicios de Terceros</h2>
            <p>
              Utilizamos servicios de terceros, como Google Analytics, para
              analizar el tráfico y el comportamiento de los usuarios en
              nuestro sitio web. Estos servicios pueden recopilar información
              sobre tu actividad en línea. Para más información, consulta la
              política de privacidad de Google Analytics.
            </p>

            <h2>Control de la Información Personal</h2>
            <p>
              Tienes derecho a acceder, rectificar, suprimir, oponerte,
              limitar, portar o retirar tu consentimiento sobre el uso de tus
              datos personales. Para ejercer estos derechos, puedes
              contactarnos utilizando los datos proporcionados al principio de
              esta política.
            </p>
            <ul>
              <li>
                <strong>Derecho de acceso:</strong> Puedes solicitar una copia
                de los datos personales que tenemos sobre ti.
              </li>
              <li>
                <strong>Derecho de rectificación:</strong> Puedes corregir
                datos personales inexactos.
              </li>
              <li>
                <strong>Derecho de supresión:</strong> Puedes solicitar la
                eliminación de tus datos en determinadas circunstancias.
              </li>
              <li>
                <strong>Derecho de oposición:</strong> Puedes oponerte al
                procesamiento de tus datos en ciertas situaciones.
              </li>
              <li>
                <strong>Derecho a la portabilidad de los datos:</strong>{' '}
                Puedes solicitar que te proporcionemos tus datos en un formato
                estructurado y comúnmente usado.
              </li>
              <li>
                <strong>Derecho a la limitación del tratamiento:</strong>{' '}
                Puedes solicitar que limitemos el procesamiento de tus datos
                en determinadas circunstancias.
              </li>
            </ul>
            <p>
              Para ejercer cualquiera de estos derechos, puedes enviarnos una
              solicitud por correo electrónico a{' '}
              <a href="mailto:aitor@mataseguros.com">aitor@mataseguros.com</a>{' '}
              o por correo postal a 514 N Armour St, 60614 Chicago, Illinois.
            </p>

            <h2>Transferencias Internacionales de Datos</h2>
            <p>
              En caso de que tus datos sean transferidos fuera del Espacio
              Económico Europeo (EEE), nos aseguramos de que se implementen
              las medidas de seguridad adecuadas para proteger tus datos
              personales durante todo el proceso de transferencia.
            </p>

            <h2>Modificaciones a la Política de Privacidad</h2>
            <p>
              Nos reservamos el derecho de modificar esta Política de
              Privacidad en cualquier momento. Cualquier cambio será publicado
              en esta página, y te recomendamos revisar esta política
              periódicamente para estar informado sobre cómo protegemos tus
              datos personales.
            </p>
          </Information>
        </PostItemStyled>
      </div>
    </SectionStyled>
  </>
);

/*
const TermsConditions = ({ data }) => {
  const terms = data.terms.nodes[0];

  return (
    <>
      <SEO title="Terminos y condiciones" />
      <Terms terms={terms} />
    </>
  );
};
*/

export default TermsConditions;
